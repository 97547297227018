import axios from "axios"
import { toast } from "react-toastify"
import { store } from '../redux/store'
import ErrorToast from "../utility/componentsToast/ErrorToast"
import SessaoToast from "../utility/componentsToast/SessaoToast"
import { handleWithError400, handleWithError422, handleWithError401, handleWithError429 } from "./HelpersForStatusCode"
import { environment } from "./endpointsBase"
const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  return item ? JSON.parse(item) : {}
}
const api = axios.create({
  baseURL: environment.entrada
  // baseURL: "https://sandboxapi.tributei.net/api"
})
api.defaults.headers.common['Authorization'] = `Bearer ${initialUser().access_token}`
store.subscribe(() => {
  api.defaults.headers.common['Authorization'] = `Bearer ${initialUser().access_token}`
})

api.interceptors.response.use((response) => {

  return response
}, error => {
  switch (error.response?.status) {
    case 400:
      handleWithError400(error)
      break
    case 401:
      handleWithError401(error)
      break
    case 403:
      toast(<ErrorToast error='Você não possui permissão para isso!' />, {toastId: 'pedidos'})
      break
    case 409:
      handleWithError422(error)
      break
    case 422:
      handleWithError422(error)
      break
    case 429:
      handleWithError429(error)
      break
    case 500:
      toast(<ErrorToast error="OPS! parece que algo deu errado. Por favor tente novamente" />, { autoClose: 3000 })
      break
    case 503:
      toast(<ErrorToast error="OPS! parece que algo deu errado. Por favor tente novamente" />, { autoClose: 3000 })
      break
    default:
      break
  }

  return Promise.reject(error)
})

export default api
